.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  background-color: rgba(238,238,238,1);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputFields {
  margin-top: 16px;
  margin-bottom: 16px;
}

.statement,a {
    text-decoration: none;
    color: black
}

/*drag drop*/

#fileUploadForm {
  height: 10rem;
  width: 100%;
  text-align: center;
  position: relative;
}

#fileInputLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-color: #ccd6e2;
  background-color: #f4f7f9;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
}

#fileInputLabel.dragging {
  background-color: #feffff;
}

#fileInputLabelOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 1rem;
}

.MuiPopper-root {
  z-index: 9999;
}
